import React, { useCallback, useEffect, useMemo, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import { compact, flatten, uniq } from "lodash";
import { AppButton, NavButton } from "../Components/Buttons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import useIsMounted from "../lib/hooks/useIsMounted";
import {
  appColors,
  appFonts,
  useAppTheme,
  appDialogTransparentPaperProps,
} from "../theme";
import { gotoNextTaskStep, gotoPrevTaskStep, gotoTaskRoute, setTaskTransitionPending } from "../features/task/taskSlice";
import { updateDeltaSignedFormsDataChanged } from "../features/delta/deltaSlice";
import { filterFullNameInput } from "../utility/inputFilterFunctions";
import { Header } from "../Components/Header";
import { BackButton } from "../Components/Buttons";
import FormErrorBanner from "../Components/Errors/FormErrorBanner";
import CustomCheckbox from "../Components/CustomCheckbox";
import CustomTextField from "../Components/CustomTextField";
import { getConsentFormTitlesApi, getConsentFormContentsApi } from "../api/GetConsentFormsAPI";
import { Document, Page, pdfjs } from "react-pdf";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { ReactComponent as RightArrowSvg } from "../assets/icons/arrow_right.svg";
import { signAndSavePdfForms } from "../lib/pdf-forms/pdfFormHelpers";
import { SignatureData } from "../lib/pdf-forms/collectPdfCustomData";
import LoadingSkeleton from "../Components/LoadingSkeleton";
import Aem, { AemContentTypes } from "../lib/aem/components/Aem";
import LoadingScrim from "../Components/LoadingScrim";
import { AccAutoFillTokens, AriaLive } from "../data/accessibility/AccessibilityData";
import { IsDocTypeRequiredByAnyCdoVisitTypes } from "../data/CDO";
import {
  GetDocTypeFromTemplateKey,
  GetFormTemplateKeysFromDocCounts,
  PciDocTypes,
} from "../data/docData";
import { SingleApptState } from "../features/appt/apptSlice";
import { tracker } from "../appTracker";
import "react-pdf/dist/esm/Page/TextLayer.css";
import workerContent from "../../src/pdf.worker.min.json";
import PatientRegistration from "../Components/PatientRegistration/PatientRegistration";
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';




var workerBlob = new Blob([workerContent], { type: 'text/javascript' });
var workerBlobURL = URL.createObjectURL(workerBlob);
pdfjs.GlobalWorkerOptions.workerSrc = workerBlobURL


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  navSection: {
    marginTop: "24px",
    paddingBottom: "24px",
  },
  heading: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
  },
  title: {
    fontFamily: appFonts.regular,
    fontSize: "18px",
    marginTop: "20px",
  },
  loadingArea: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  inpBox: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: "#152831",
    "& .MuiInputLabel-shrink": {
      fontSize: "14px",
      transform: "translate(0, 1.5px)",
    },
    "& .MuiFormLabel-root": {
      fontFamily: appFonts.semibold,
      letterSpacing: 0,
    },
    marginBottom: "13px",
  },
  sectionArea: {
    marginTop: "22px",
    color: appColors.white,
  },
  sectionAreaEmpty: {
    color: appColors.whiteA05,
  },
  sectionContent: {
    fontSize: "18px",
    fontFamily: appFonts.medium,
    lineHeight: 1.5
  },
  fullNameArea: {
    marginTop: "17px",
  },
  fullNameInputErrorArea: {
    fontSize: "14px",
    color: appColors.white,
    backgroundColor: "rgba(240, 89, 115,0.2)",
  },
  fullNameInputError: {
    padding: "11px",
    fontFamily: appFonts.bold,
  },
  infoText: {
    marginTop: "24px",
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: "#ff612b !important",
  },
  relationshipArea: {
    marginTop: "14px",
  },
  formListSection: {
    marginTop: "16px",
    backgroundColor: "rgba(0,0,0,0.2)",
    border: "1px solid rgba(255,255,255,0.15)",
    borderRadius: "4px",
    opacity: 0.75,
    display: "flex",
  },
  formRow: {
    minHeight: "47px",
    border: `1px solid transparent`,
    borderBottom: "1px solid rgba(255,255,255,0.15)",
    display: "flex",
  },
  formRowRequiredError: {
    border: `1px solid ${appColors.darkError}`,
  },
  requiredIconSupescriptArea: {
  },
  checkBox: {
    borderRight: "1px solid rgba(255,255,255,0.15)",
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    width: "46px",
    height: "100%",
  },
  checkboxFormLabel: {
    padding: "0 8px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  formLabel: {
    margin: "11px",
    alignSelf: "center",
    display: "flex",
    cursor: "pointer",
    width: "calc(100% - 100px)",
    fontFamily: appFonts.medium,
    fontSize: "18px",
  },
  formLabelText: {
  },
  formLabelRequiredIcon: {
    margin: "-4px 1px 1px 1px",
  },
  rightArrow: {
    marginRight: "11px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  btnCancel: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    maxWidth: "200px",
    maxHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
    textTransform: "none",
    backgroundColor: "rgba(255,255,255,0.1)",
    border: "1px solid #FFFFFF",
    fontSize: "16px",
  },
  pdfHeader: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    color: appColors.white,
    paddingTop: "20px",
    paddingBottom: "30px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  errorScrim: {
    padding: "16px",
    fontSize: "14px",
    color: appColors.darkError,
    backgroundColor: "rgba(0,0,0,0.4)",
    // marginBottom: "16px",
  },
  relationship: {
    color: "white",
    paddingTop: "10px",
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "rgba(255,255,255,0.5)",
      color: "rgba(255,255,255,0.5)",
    },
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  pdfHeaderTitle: {
    marginBottom: "0px",
    width: "70%",
  },
  pdfPage: {
    paddingBottom: "20px",

    "& .react-pdf__Document": {
      "& .react-pdf__Page": {
        "& .react-pdf__Page__canvas": {
          marginBottom: "15px",
        }
      },
    }
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormResponse {
  key: string;
  name: string;
  lang: string;
  required: boolean;
  content: string | null | undefined;
}


const ConsentFormScreen: React.FC = () => {
  const width = useWindowWidth();
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  const checkboxInputIdPrefix = useId();
  const fullNameInputId = useId();
  const fullNameDescribeId = useId();
  const fullNameErrorId = useId();
  const repRelInputId = useId();
  const dialogTitleId = useId();

  // set to true to skip loading of forms when docCounts are high enough that no forms would show
  const skipLoadingFormsTemplateKeysEmpty = true;

  // when true, navgivate to the next step when no forms would show
  const pendingStartRoute = useAppSelector<string>((state) => state.task.pendingStartRoute);
  const autoNavigateWhenFormsTemplateKeysEmpty = (pendingStartRoute !== "consent/consentForm");

  const languageIsoCode = useAppSelector((state) => state.preferences.languageIsoCode);
  const allAppointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);

  const filterActiveAppointments = useCallback((apptItems: SingleApptState[]) => {
    return (apptItems || []).filter(v => (v.isConfirmed && !v.preRegDone && v.preRegRequired));
  }, []);
  const filteredAppointmentItems = useMemo<SingleApptState[]>(() =>
    filterActiveAppointments(allAppointmentItems),
    [filterActiveAppointments, allAppointmentItems]
  );

  const advancedDirectiveEnabled: boolean = useAppSelector<boolean>((state) => !!(state.patient?.isAdultPatient && state.appt.needsAdvancedDirectiveForm));
  const advancedDirectiveSelection: string = useAppSelector<string>((state) => state.patient?.advancedDirective?.selection || "");
  const relation = useAppSelector((state) => state.preferences.relationship);
  const persona = useAppSelector((state) => state.preferences.persona);
  const defaultRelation: string = "Self";
  const [loading, setLoading] = useState<boolean>(true);    // show the loading spinner when loading doc info
  const [visible, setVisible] = useState<boolean>(false);   // don't show any UI until after we know the doc counts (for skip ahead logic)
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [moveRouteDone, setMoveRouteDone] = useState<boolean>(false);   // used to keep track of route navigations to avoid duplicate calls

  const [checkList, setCheckList] = useState<any[]>([]);
  const [requiredList, setRequiredList] = useState<any[]>([]);
  const [formList, setFormList] = useState<FormResponse[]>([]);
  const [activeIdx, setActiveIdx] = useState<number>();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [formModified, setFormModified] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);
  const [viewPdf, setViewPdf] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [pdfBase64, setPdfBase64] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [pdfSnackbarOpen, setPdfSnackbarOpen] = useState(false);
  const [isviewdisabled, setIsviewdisabled] = useState(false);

  const fullNameRequired: boolean = (formList?.length > 0 && checkList?.length > 0);

  const fullNameLabel: string = Aem.get(
    "BODY_PATIENTFORMSCONSENT_TEXT_3",
    "Full Name"
  );

  const relationshipLabel: string = Aem.get(
    "BODY_PATIENTFORMSCONSENT_TEXT_4",
    "Relationship to Patient"
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const isFormRequiredAndUnchecked = useCallback((form: any): boolean => {
    let retval: boolean = false;
    if (form?.required) {
      retval = !checkList.includes(form.idx);
    }
    return retval;
  }, [checkList]);

  const checkAllRequiredFormsChecked = useCallback((): boolean => {
    let retval: boolean = false;
    if (requiredList?.length > 0) {
      let firstMissingRequiredForm: any = requiredList.find(v => {
        return !checkList.includes(v);
      });
      retval = (firstMissingRequiredForm === undefined);
    } else {
      // no required forms
      retval = true;
    }
    return retval;
  }, [checkList, requiredList]);

  const loadFormContent = async (idx) => {
    setFormLoading(true);
    let templateKey: string = formList[idx]?.key;
    if (!templateKey) { return false; }
    
    await getConsentFormContentsApi(languageIsoCode, [templateKey])
      .then((res) => {
        let content = (res && res[0] && res[0].content) || null;
        if (content) {
          if(formList[idx]?.key === 'caliPatRegForm_eng' && formList[idx]?.name === 'Patient Registration'){            
            handleConfirmYes();
            content = pdfBase64;            
          }
          formList[idx].content = content;
        }
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
      });
  };

  const clearFormContent = () => {
    let newList = formList.map(form => {
      form.content = null;
      return form;
    });
    setFormList(newList);
  };

  const handleBackClick = async () => {
    await dispatch(gotoPrevTaskStep());
  };

  const handleNextClick = async () => {
    dispatch(setTaskTransitionPending(true));

    // NOTE: this API call will take a long time.  like over 20 seconds.
    // it was originally designed to run asynchronousely in the background,
    // but that was changed because checkin complete API was trying to send 
    // the forms to the CDO before they were finished.
    // for now, we can await here until a solution can be created which 
    // does not force the user to wait at this screen transition.
    let success = await doSignAndSaveConsentForms();
    if (!success) {
      dispatch(gotoTaskRoute("techDifficultiesError"));
      return;
    }

    await gotoNextTaskStepOnce();
  };

  const gotoNextTaskStepOnce = useCallback(async () => {
    if (moveRouteDone) {
      console.log("ConsentForm skipping next route");
      return;
    }
    if (isMounted()) {
      await setMoveRouteDone(true);
    }
    return dispatch(gotoNextTaskStep());
  }, [dispatch, isMounted, moveRouteDone, setMoveRouteDone]);

  useEffect(() => {
    let allCdoVisitTypes: string[] = uniq(filteredAppointmentItems).map(apptItem => apptItem.cdoVisitType);

    // filter all appointment which are confirmed and pre-reg form not signed
    let apptTemplateKeys: string[][] = filteredAppointmentItems.map(apptItem => {
      return GetFormTemplateKeysFromDocCounts(
        apptItem.docCounts,
        apptItem.appointmentTypeId,
        languageIsoCode,
        advancedDirectiveEnabled,
        advancedDirectiveSelection
      );
    });

    let templateKeys: string[] = compact(uniq(flatten(apptTemplateKeys)));
    if (!templateKeys || templateKeys.length === 0) {
      if (skipLoadingFormsTemplateKeysEmpty) {
        setLoading(false);

        if (autoNavigateWhenFormsTemplateKeysEmpty) {
          gotoNextTaskStepOnce();
        } else {
          // there are no forms to display, so the page and wait for the user to click continue
          setVisible(true);
        }
        return;
      }
    }

    getConsentFormTitlesApi(languageIsoCode, templateKeys)
      .then((formList) => {
        let requiredList: any = [];
        formList = (formList || []).map((form, idx) => {
          let docType: PciDocTypes = GetDocTypeFromTemplateKey(form?.key || "");
          form.idx = idx;
          form.required = IsDocTypeRequiredByAnyCdoVisitTypes(docType, allCdoVisitTypes);
          if (form.required) {
            requiredList.push(idx);
          }
          return form;
        });

        setFormList(formList);
        setRequiredList(requiredList);
        setLoading(false);

        if (autoNavigateWhenFormsTemplateKeysEmpty && (!formList || formList.length === 0)) {
          gotoNextTaskStepOnce();
        } else {
          setVisible(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setVisible(true);
      });
  }, [gotoNextTaskStepOnce, filteredAppointmentItems, languageIsoCode, skipLoadingFormsTemplateKeysEmpty, autoNavigateWhenFormsTemplateKeysEmpty, advancedDirectiveSelection, advancedDirectiveEnabled]);

  // watch form state and update error conditions
  useEffect(() => {
    if (loading || formLoading) { return; }

    let newError: boolean = false;
    let newFullNameError: boolean = false;
    if (formList?.length > 0) {
      if (fullNameRequired) {
        let tmpName: string = (fullName || "").trim();
        if (!tmpName) {
          newError = true;
          newFullNameError = true;
        }
      }
      if (requiredList?.length > 0 && !checkAllRequiredFormsChecked()) {
        newError = true;
      }
    } else {
      // we don't have any forms to show
      newError = false;
    }

    if (newFullNameError !== fullNameError) {
      setFullNameError(newFullNameError);
    }
    if (newError !== error) {
      setError(newError);
    }
  }, [loading, formLoading, error, fullName, fullNameRequired, fullNameError, formList, checkList, requiredList, checkAllRequiredFormsChecked]);

  // watch for form errors and send them to the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "consent", alert: "consent error", value: error },
    ]);
  }, [error]);

  const doSignAndSaveConsentForms = async (): Promise<boolean> => {
    // grab the list of checked template keys
    let checkedForms: any[] = (formList || []).filter((v, idx) => {
      return v?.key && checkList.includes(idx);
    });

    if (checkedForms.length === 0) {
      // we do not have any forms to sign, 
      // we can return true without calling the API
      // we can assume that the button will be disabled if there is a required form
      return true;
    }

    // let the delta tracker know that PDF signed forms have changed
    dispatch(updateDeltaSignedFormsDataChanged(true));

    let templateKeys: string[] = checkedForms.map((v) => v?.key);
    let cdoFormTypes: string[] = checkedForms.map((v) => v.cdoFormType);
    let signatureData: SignatureData = {
      signature: fullName,
      relation: relation || defaultRelation,
    };
    return signAndSavePdfForms(templateKeys, cdoFormTypes, signatureData);
  };

  if (!visible) {
    return (
      <Grid container direction="column" className={classes.root}>
        <Grid container direction="row" className={classes.loadingArea}>
          {loading && <LoadingSkeleton show={loading} />}
        </Grid>
      </Grid>
    );
  }



  const handleConfirmYes = () => {
    setIsviewdisabled(true);
    setIsConfirmDialogOpen(false);
    setPdfSnackbarOpen(true);
    setFormLoading(true);
    setTimeout(() => {
      setFormLoading(false);
      exportPDF();
      setSuccessSnackbarOpen(true);

    }, 9000);    
  };
  const exportPDF = async () => {
    try {
      const pages = ['pageOne', 'pageTwo', 'pageThree', 'pageFour'];
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 185;
      const pdfHeight = 295;
      const scale = 3;

      for (let i = 0; i < pages.length; i++) {
        const pageElement = document.getElementById(pages[i]);

        if (pageElement) {
          pageElement.style.width = (i === 0 ? '1030px' : '800px');

          const canvas = await html2canvas(pageElement, {
            useCORS: true,
            scale,
            width: (i === 0 ? 1050 : 820),
            height: pageElement.scrollHeight * ((i === 0 ? 1050 : 820) / pageElement.scrollWidth),
          });

          const imgData = canvas.toDataURL('image/png', 1.0);
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (i !== 0) pdf.addPage();

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight > pdfHeight ? pdfHeight : imgHeight);


          // pageElement.style.fontSize = '';
          // pageElement.style.width = '';
        }
      }

      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (typeof base64data === 'string') {
          setPdfBase64(base64data);
          setSuccessSnackbarOpen(false)
          setViewPdf(true);
        } else {
          console.error('Error generating base64:', error);
        }
      };
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const handleConfirmNo = () => {
    setIsConfirmDialogOpen(false);
  };


  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(theme.navContent, theme.nowrap)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgBlueGrad)}
        >
          <Header showMenuButton={true} />

          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_PATIENTFORMSCONSENT_TEXT_1">
                  Consent Forms
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <ConsentSVG/> */}
              <Aem cid="HEADER_PATIENTFORMSCONSENT_LOGO_1" type={AemContentTypes.imageUrl}></Aem>
            </Grid>
          </Grid>
        </Grid>

        {error && formModified ? <FormErrorBanner aemId="BODY_PATIENTFORMSCONSENT_TEXT_5" /> : null}
        <Grid
          container
          direction="column"
          className={clsx(theme.navSection, theme.nowrap, classes.navSection)}
        >
          <Grid item className={classes.sectionContent}>
            <Aem cid="BODY_PATIENTFORMSCONSENT_TEXT_1">
              Please read the following forms and enter your name below to
              agree.
            </Aem>
          </Grid>

          <Grid container direction="row" className={classes.formListSection}>
            {loading && (
              <LoadingSkeleton show={loading} />
            )}
            {!loading && formList.map((form, idx) => {
              const cbInputId = `${checkboxInputIdPrefix}-cb-${idx}`;
              const cbLabelId = `${checkboxInputIdPrefix}-lbl-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  container
                  direction="row"
                  xs={12}
                  className={clsx(classes.formRow, { [classes.formRowRequiredError]: formModified && isFormRequiredAndUnchecked(form) })}
                >
                  <Grid item className={classes.checkBox}>
                    <CustomCheckbox
                      checkboxProps={{
                        id: cbInputId,
                        checked: checkList.includes(idx),
                        required: form.required,
                        inputProps: {
                          "aria-labelledby": cbLabelId,
                        },
                        onChange: (e) => {
                          let newChecked: boolean = !!e.target.checked;
                          if (newChecked && !checkList.includes(idx)) {
                            let newList = [...checkList, idx];
                            setCheckList(newList);
                            setFormModified(true);
                          } else if (!newChecked && checkList.includes(idx)) {
                            let newList = checkList.filter((val) => val !== idx);
                            setCheckList(newList);
                            setFormModified(true);
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    className={classes.formLabel}
                    onClick={async () => {
                      if (!checkList.includes(idx)) {
                        let newList = [...checkList, idx];
                        setCheckList(newList);
                        setFormModified(true);
                      }
                      setActiveIdx(idx);
                      loadFormContent(idx);
                      setOpenDialog(true);
                      setViewPdf(false);
                      setIsviewdisabled(false);
                    }}
                  >
                    <Grid item id={cbLabelId} className={classes.formLabelText}>
                      {form.name}
                    </Grid>
                    {form.required && (
                      <Grid item className={classes.formLabelRequiredIcon}>
                        *
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    className={classes.rightArrow}
                    onClick={() => {
                      if (!checkList.includes(idx)) {
                        let newList = [...checkList, idx];
                        setCheckList(newList);
                        setFormModified(true);
                      }
                      setActiveIdx(idx);
                      loadFormContent(idx);
                      setOpenDialog(true);
                      setViewPdf(false);
                      setIsviewdisabled(false);
                    }}
                  >
                    <RightArrowSvg />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          {/* sign section */}

          <Grid
            item
            className={clsx(classes.sectionArea, { [classes.sectionAreaEmpty]: (checkList.length === 0) })}
          >
            <Grid item id={fullNameDescribeId} className={classes.sectionContent}>
              <Aem cid="BODY_PATIENTFORMSCONSENT_TEXT_2">
                I understand that by typing my name below, I am digitally
                signing these documents and have reviewed the selected forms
                above.
              </Aem>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.fullNameArea}
            >
              <Grid item>
                <CustomTextField
                  id={fullNameInputId}
                  className={classes.inpBox}
                  required={checkList.length > 0}
                  disabled={checkList.length === 0 ? true : false}
                  error={fullNameRequired && fullNameError && formModified}
                  label={fullNameLabel}
                  autoComplete={AccAutoFillTokens.name}
                  type="text"
                  fullWidth={true}
                  variant="standard"
                  value={checkList.length > 0 ? fullName : ""}
                  inputProps={{
                    "aria-describedby": fullNameDescribeId,
                    "aria-errormessage": fullNameErrorId,
                  }}
                  onChange={(e) => {
                    setFullName(filterFullNameInput(e.target.value));
                    setFormModified(true);
                  }}
                />
                {fullNameRequired && fullNameError && formModified ? (
                  <Grid
                    container
                    direction="row"
                    className={classes.fullNameInputErrorArea}
                    aria-live={AriaLive.polite}
                  >
                    <Grid
                      item
                      id={fullNameErrorId}
                      className={classes.fullNameInputError}
                    >
                      <Aem cid="BODY_PATIENTFORMSCONSENT_TEXT_6">
                        Please enter your full name.
                      </Aem>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid
                item
                className={classes.relationshipArea}
              >
                <CustomTextField
                  id={repRelInputId}
                  className={classes.inpBox}
                  required={checkList.length > 0}
                  disabled={checkList.length === 0 ? true : false}
                  label={relationshipLabel}
                  autoComplete={AccAutoFillTokens.off}
                  type="text"
                  fullWidth={true}
                  variant="standard"
                  value={persona === "patient" ? "Self" : relation}
                  inputProps={{
                    className: classes.relationship,
                  }}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(theme.headerText, classes.infoText)}>
            <Aem cid="BODY_PATIENTFORMSCONSENT_TEXT_7">
              Please note that you may need to sign additional forms once you arrive at the clinic.
            </Aem>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        className={theme.navStickyFooter}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={handleBackClick}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            disabled={loading || formLoading || error}
            accentColor={"blue"}
            label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
            fullWidth={true}
            trackName="next"
            trackLocation="nav footer"
            onClick={handleNextClick}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          clearFormContent();
        }}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >                     
        <Grid className={theme.filly}>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.pdfHeader}
          >
            <Grid
              item
              id={dialogTitleId}
              className={clsx(theme.headerTitle, classes.pdfHeaderTitle)}
            >
              {formList.length !== 0 && activeIdx != null
                ? formList[activeIdx].name
                : ""}
            </Grid>

            {viewPdf ? (
              <Grid item>
                <AppButton
                  variant="outlined"
                  disableElevation
                  className={classes.btnCancel}
                  trackName="cancel"
                  trackLocation="overlay"
                  onClick={() => setOpenDialog(false)}
                >
                  <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
                </AppButton>
              </Grid>
            ) : (
              <>
                <Grid item>
                  {formList.length !== 0 && activeIdx != null && activeIdx !== undefined && formList[activeIdx].key !== "caliPatRegForm_eng" && (

                    <Grid item>
                      <AppButton
                        variant="outlined"
                        disableElevation
                        className={classes.btnCancel}
                        trackName="cancel"
                        trackLocation="overlay"
                        onClick={() => setOpenDialog(false)}
                      >
                        <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
                      </AppButton>

                    </Grid>
                  )}

                  {/* </Grid> */}

                  {/* {formList.length !== 0 && activeIdx != null && activeIdx !== undefined && formList[activeIdx].key === "caliPatRegForm_eng" && (

                    // <Grid item>
                    <AppButton
                      variant="outlined"
                      disableElevation
                      className={classes.btnCancel}
                      trackName=""
                      trackLocation="overlay"
                      onClick={() => handleViewClick()}
                      disabled={isviewdisabled}
                    >
                      <>View</>
                    </AppButton>
                  
                    // </Grid>
                  )} */}
                </Grid>
              </>
            )}


     <Snackbar
        open={successSnackbarOpen}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
      <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
      Please wait Pdf is generating....!
      </Alert>
     </Snackbar>
 
      {/* <Snackbar
        open={pdfSnackbarOpen}
        autoHideDuration={7000} 
        onClose={() => setPdfSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setPdfSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
          
          Generating Pdf....
        </Alert>
      </Snackbar> */}
          
           <Dialog
            open={isConfirmDialogOpen}
            onClose={handleConfirmNo}
          >
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Once you click on yes pdf will be generated,
                are you sure you want to save the details.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmNo} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmYes} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog> 
          
          </Grid>
          {/* pdf content */}
          <Grid className={classes.pdfPage}
            container
            direction="column"
            alignContent="center"
          >
            {formLoading && <LoadingScrim showDelayMS={150} />}
            {!formLoading && activeIdx !== undefined && (
              <>
                {formList[activeIdx].key === "caliPatRegForm_eng" ? (
                  viewPdf ? (
                    <Document
                      file={pdfBase64}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page, pageIdx) => (
                          <Page renderTextLayer={false} key={pageIdx} pageNumber={page} width={((width !== null) ? (width * 0.9) : 0.9)} />
                        ))}
                    </Document>
                  ) : (
                    <div style={{opacity: 0}}><PatientRegistration /></div>
                    // <PatientRegistration />
                  )
                ) : (

                  <Document
                    file={formList[activeIdx].content}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page, pageIdx) => (
                        <Page renderTextLayer={false} key={pageIdx} pageNumber={page} width={((width !== null) ? (width * 0.9) : 0.9)} />
                      ))}
                  </Document>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default ConsentFormScreen;