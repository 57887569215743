import { InsuranceProviders } from "../../data";
import { LanguageIsoKvPairs } from "../../data/languageData";
import { EmergencyRelationshipsList } from "../../data/relationshipsData";
import Aem from "../../lib/aem/components/Aem";


export const genderItems = [
    {
        "Key": "Female",
        "Value": "Female"
    },
    {
        "Key": "Female-to-Male/Transgender Male",
        "Value": "Female-to-Male/Transgender Male"
    },
    {
        "Key": "Genderqueer/Non-Binary",
        "Value": "Genderqueer/Non-Binary"
    },
    {
        "Key": "Male",
        "Value": "Male"
    },
    {
        "Key": "Male-to-Female/Transgender Female",
        "Value": "Male-to-Female/Transgender Female"
    },
    {
        "Key": "Prefer Not to Disclose",
        "Value": "Prefer Not to Disclose"
    },
    {
        "Key": "Other",
        "Value": "Other"
    }
];

export const preferredPronounsItems = [{
    "Key": "He/Him/His",
    "Value": "He/Him/His"
}, {
    "Key": "She/Her/Hers",
    "Value": "She/Her/Hers"
}, {
    "Key": "They/Them/Theirs",
    "Value": "They/Them/Theirs"
}, {
    "Key": "Other",
    "Value": "Other"
}
];
export const sexAssignedBirthItems = [
    {
        "Key": "M", //sexAtBirth
        "Value": "Male" //sexAtBirthValue
    },
    {
        "Key": "F",
        "Value": "Female"
    },
    {
        "Key": "I",
        "Value": "Indeterminate"
    },
    {
        "Key": "U",
        "Value": "Unknown"
    }
];
export const maritalStatusItems = [
    {
        "Key": "SINGLE",
        "Value": "Single"
    },
    {
        "Key": "MARRIED",
        "Value": "Married"
    },
    {
        "Key": "WIDOWED",
        "Value": "Widowed"
    },
    {
        "Key": "SEPARATED",
        "Value": "Separated"
    },
    {
        "Key": "DIVORCED",
        "Value": "Divorced"
    }
];
export const ethnicityItems =
[
    {
        "Key": "Hispanic or Latino or Spanish Origin",
        "Value": "Hispanic, Latino, or Spanish Origin"
    },
    {
        "Key": "Not Hispanic or Latino or Spanish Origin",
        "Value": "Not Hispanic, Latino, or Spanish Origin"
    },
    {
        "Key": "Declined",
        "Value": "Prefer not to disclose"
    }
];
export const raceItems = 
[
    {
        "Key": "AM INDIAN-AK NATIVE",
        "Value": "American Indian/Alaskan Native"
    },
    {
        "Key": "ASIAN",
        "Value": "Asian"
    },
    {
        "Key": "BLACK-AFR AMERICAN",
        "Value": "Black/African-American"
    },
    {
        "Key": "NATIVE HI-PAC ISL",
        "Value": "Native Hawaiian/Pacific Islander"
    },
    {
        "Key": "WHITE-CAUCASIAN",
        "Value": "White/Caucasian"
    },
    {
        "Key": "DECLINED TO STATE",
        "Value": "Declined to state"
    }
];

export const preferredPhoneTypes = [
    {Key: 'cellPhone', Value: 'cellPhone'},
    {Key: 'homePhone', Value: 'homePhone'},
    {Key: 'workPhone', Value: 'workPhone'},
    {Key: 'workPhoneExtn', Value: 'workPhoneExtn'}
  ];

// export const ethnicityItems: AemKVPair[] = Aem.getListKVPairs(
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_6",
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_6",
//     Aem.toKvPairs(EthnicityList)
//   );
  
export const aemLanguageOptions: any[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_CODES",
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_LIST",
    LanguageIsoKvPairs,
);
// export const preferredPronounsItems: AemKVPair[] = Aem.getListKVPairs(
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_3",
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_3",
//     Aem.toKvPairs(PreferredPronounsList)
// );
export const aemEmergencyRelationshipOptions: any[] = Aem.getList(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_LIST_1",
    EmergencyRelationshipsList
);
// export const aemRelationshipOptions: any[] = Aem.getList(
//     "BODY_SELFIDENTIFY_TEXT_LIST_1", RelationshipsList
// );
export const aemRelationshipOptions = ["", "Spouse", "Parent", "Child", "Domestic Partner", "Guarantor", "Other"];
export const InsurenceProviderItems: any[] = Aem.getList(
    "BODY_INSURANCE_INFO_LIST_2",
    InsuranceProviders
);

// export const genderItems: AemKVPair[] = Aem.getListKVPairs(
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_2",
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_2",
//     Aem.toKvPairs(GenderIdentityList)
// );
// export const sexAssignedBirthItems: AemKVPair[] = Aem.getListKVPairs(
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_1",
//     "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_1",
//     Aem.toKvPairs(SexAssignedBirthList)
// );
// export const maritalStatusItems: AemKVPair[] = Aem.getListKVPairs(
//   "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_4",
//   "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_4",
//   Aem.toKvPairs(MaritalStatusList)
// );