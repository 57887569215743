export interface PatientDemographics {
    patientId: string | any;
    primaryLanguage: demographics;
    sexAtBirth: demographics;
    genderIdentity: demographics;
    preferredPronoun: demographics;
    religiousAffiliation: demographics;
    maritalStatus: demographics;
    race: demographics;
    ethnicity: demographics;
    address: demographics_address[];
    telecom: demopraphics_telecom[];
    emergencyContact: demographics_emergencycontact;

}
export interface demographics {
    system: string | any;
    code: string | any;
    display: string | any;
    codingSystemName: string | any;
}
export interface demographics_address {
    use: string | any;
    line: string[];
    city: string | any;
    state: string | any;
    postalCode: string | any;
}
export interface demopraphics_telecom {
    system: string | any;
    use: string | any;
    value: string | any;
    rank: number;
    consentToContact:  string | any;
}
export interface demographics_emergencycontact {
    name: emergencycontact_name;
    relationship: string,
    contactInformation: emergency_contactInformation[];
}
export interface emergency_contactInformation {
    system: string | any;
    value: string | any;
    use: string | any;
}
export interface emergencycontact_name {
    use: string | any;
    text: string | any;
    family: string | any;
    given: string[];
}

const getData = (Key, Value) => {
    return {
        system: null,
        code: Key,
        display: Value,
        codingSystemName: null
    };
};
export const apicall_PatientData = (formData: any) => {
    const patientId = formData.patientId;
    const primaryLanguage = getData(formData.cdoLanguageKey, formData.cdoLanguageName);
    const sexAtBirth = getData(formData.demographics_sexAtBirth, formData.demographics_sexAtBirthValue);
    const genderIdentity = getData(formData.demographics_genderIdentity, formData.demographics_genderIdentityValue);
    const preferredPronoun = getData(formData.demographics_preferredPronoun, formData.demographics_preferredPronounValue);
    const religiousAffiliation = getData(formData.demographics_religiousAffiliation, formData.demographics_religiousAffiliationValue);
    const maritalStatus = getData(formData.demographics_maritalStatus, formData.demographics_maritalStatusValue);
    const race = getData(formData.demographics_race, formData.demographics_raceValue);
    const ethnicity = getData(formData.demographics_ethnicity, formData.demographics_ethnicityValue);

    let demographics_address: demographics_address = {
		use: formData.preferredPhoneType,
		line: [formData.address1, formData.address2],
		city: formData.city,
		state: formData.state,
		postalCode: formData.zipcode
	}
    let phoneRanks: string[] = ["homePhone", "workPhone", "cellPhone"];
    phoneRanks.sort((a: string, b: string) => {
      if (a === formData.preferredPhoneType) {
        return -1;
      }
      if (b === formData.preferredPhoneType) {
        return +1;
      }
      return 0;
    });
    let demopraphics_telecom_Home: demopraphics_telecom = {
		system: "Phone",
		use: "Home",
		value: formData.homePhone,
		consentToContact: null,
		rank: phoneRanks.indexOf("homePhone") + 1,
	}
    let demopraphics_telecom_Work: demopraphics_telecom = {
		system: "Phone",
		use: "Work",
		value: formData.workPhone,
		consentToContact: null,
		rank: phoneRanks.indexOf("workPhone") + 1,
	}
    let demopraphics_telecom_Mobile: demopraphics_telecom = {
		system: "Phone",
		use: "Mobile",
		value: formData.cellPhone,
		consentToContact: null,
		rank: phoneRanks.indexOf("workPhone") + 1,
	}
    let demopraphics_telecom_Email: demopraphics_telecom = {
		system: "Email",
		use: "Home",
		value: formData.email,
		consentToContact: formData.consentToEmail,
		rank: phoneRanks.length + 1
	}

	let contactInformation_homePhone: emergency_contactInformation = {
		system: "Phone",
		value: formData.emergencyContact_homePhone,
		use: "Home"
	}
	let contactInformation_cellPhone: emergency_contactInformation = {
		system: "Phone",
		value: formData.emergencyContact_cellPhone,
		use: "Mobile"
	}
	let name: emergencycontact_name = {
		use: "Official",
		text: null,
		family: formData.emergencyContact_last_name,
		given: [formData.emergencyContact_first_name]
	}
	const emergencyContact: demographics_emergencycontact = {
		name: name,
		relationship: formData.emergencyContact_relationship,
		contactInformation: [contactInformation_homePhone, contactInformation_cellPhone]
	}
    const patientDemogprahics: PatientDemographics = {
		patientId: patientId,
		primaryLanguage: primaryLanguage,
		sexAtBirth: sexAtBirth,
		genderIdentity: genderIdentity,
		preferredPronoun: preferredPronoun,
		religiousAffiliation: religiousAffiliation,
		maritalStatus: maritalStatus,
		race: race,
		ethnicity: ethnicity,
		address: [demographics_address],
		telecom: [demopraphics_telecom_Home, demopraphics_telecom_Work, demopraphics_telecom_Mobile, demopraphics_telecom_Email],
		emergencyContact: emergencyContact
	}
    // patient Insurence
	let insurence_coverage1 = {
		patientId: patientId,
		payorDisplay: formData.coverage1_payorDisplay,
		memberId: formData.coverage1_memberId,
		groupNumber: formData.coverage1_groupNumber,
		policyHolder: formData.coverage1_policyHolderName,
		relationshipToInsured: {
			system: null,
			code: formData.coverage1_policyHolderRelationToPatientCode,
			display: formData.coverage1_policyHolderRelationToPatientValue,
			codingSystemName: null
		},
		type: "",
		status: 0,
		order: 1
	}
	// make Insurence API call 1
	// console.log(JSON.stringify(insurence_coverage1));
	
	let insurence_coverage2 = {
		patientId: patientId,
		payorDisplay: formData.coverage2_payorDisplay,
		memberId: formData.coverage2_memberId,
		groupNumber: formData.coverage2_groupNumber,
		policyHolder: formData.coverage2_policyHolderName,
		relationshipToInsured: {
			system: null,
			code: formData.coverage2_policyHolderRelationToPatientCode,
			display: formData.coverage2_policyHolderRelationToPatientValue,
			codingSystemName: null
		},
		type: "",
		status: 2,
		order: 2,
		"insuranceCardImages": [{
			"imageContents": "",
			"title": "SecondaryInsuranceFront"
		}, {
			"imageContents": "",
			"title": "SecondaryInsuranceBack"
		}
		]
	}
    /*
    localStorage.setItem("patientdemographics-" + patientId, encodeURIComponent(JSON.stringify(patientDemogprahics)));
	localStorage.setItem("insurence_coverage1-" + patientId, encodeURIComponent(JSON.stringify(insurence_coverage1)));
	localStorage.setItem("insurence_coverage2-" + patientId, encodeURIComponent(JSON.stringify(insurence_coverage2)));
	*/
}